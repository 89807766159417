import moment from "moment-timezone";

export const ROLES = {
    ADMINISTRATOR : 'administrator',
    EDITOR: 'editor',
    LEADER: 'leader',
    USER: 'user'
}

export const QUARTER_END_DATES = [
    moment.tz({year: new Date().getFullYear(), month: 2, day: 31, hour: 23, minute: 59, second: 59}, 'America/Monterrey').toDate(),
    moment.tz({year: new Date().getFullYear(), month: 5, day: 30, hour: 23, minute: 59, second: 59}, 'America/Monterrey').toDate(),
    moment.tz({year: new Date().getFullYear(), month: 8, day: 30, hour: 23, minute: 59, second: 59}, 'America/Monterrey').toDate(),
    moment.tz({year: new Date().getFullYear(), month: 11, day: 31, hour: 23, minute: 59, second: 59}, 'America/Monterrey').toDate(),
];

export const QUARTERS = [
    'Quarter 1',
    'Quarter 2',
    'Quarter 3',
    'Quarter 4',
];

export const SCOREBOARD_TYPE = {
    GENERAL_ACTIVITY: '#054AAF',
    ROCK: '#141414',
    PERSONAL_GOAL: '#4DA6FF'
};

export const TIMEZONE = 'America/Monterrey';