import React, { useContext, useState } from 'react';
import Head from 'next/head';
import MainContent from '../MainContent'
import './layout.css';
import Context from '../../utils/context';


const Layout = ({ title, children }) => {
    return (
        <>
            <Head>
                <title>{`${title} | Scaling Up`}</title>
            </Head>
            <MainContent>
                {children}
            </MainContent>
        </>
    )
}

export default Layout;