import React, { useState, useEffect } from 'react'
//import { CalendarIcon, MinusCircleIcon, PlusIcon } from '../../public/icons'
import { MinusIcon, PlusIcon } from 'evergreen-ui'
import './graphics.css'

const ProgressBar = ({
    v = 1,
    data,
    unity,
    quarter,
    rock,
    highCriticalNumber,
    criticalNumber,
    mediumCriticalNumber,
    lowCriticalNumber,
    currentScore: originalScore = 0,
    canEdit = false,
    slug,
    assignedPhoto,
    deadlineDates
}) => {
    const justifyRightInfo = /* canEdit &&  */ 'space-between'
    const heightOfGraph = 260
    const fourColors = ['#F28393', '#FFC312', '#6AD485', '#41A69A']
    const [currentScore, setCurrentScore] = useState(originalScore)
    const [loaded, setLoaded] = useState(false)

    // Start animation
    useEffect(() => {
        setLoaded(true)
    }, [])

    useEffect(() => {
        !canEdit && setCurrentScore(originalScore)
    })

    const calculateCurrentScore = () => {
        if (currentScore <= lowCriticalNumber) {
            return 0
        }

        if (currentScore > lowCriticalNumber && currentScore <= mediumCriticalNumber) {
            const med = (currentScore - lowCriticalNumber) / (lowCriticalNumber - mediumCriticalNumber)
            return (heightOfGraph / 4) * Math.abs(med)
        }

        if (currentScore === mediumCriticalNumber) {
            return heightOfGraph / 4
        }

        if (currentScore < mediumCriticalNumber && currentScore >= lowCriticalNumber) {
            const med = (currentScore - mediumCriticalNumber) / (lowCriticalNumber - mediumCriticalNumber)
            return (heightOfGraph / 4) * Math.abs(med)
        }

        if (currentScore <= criticalNumber && currentScore >= mediumCriticalNumber) {
            const med = (currentScore - mediumCriticalNumber) / (criticalNumber - mediumCriticalNumber)
            return heightOfGraph / 4 + (Math.abs(med) * heightOfGraph) / 4
        }

        if (currentScore <= highCriticalNumber && currentScore >= criticalNumber) {
            const med = (currentScore - criticalNumber) / (criticalNumber - highCriticalNumber)
            return heightOfGraph / 2 + (Math.abs(med) * heightOfGraph) / 4
        }

        if (currentScore > highCriticalNumber) {
            return heightOfGraph
        }
    }

    return (
        <div className='progress-bar container'>
            {/*style={{ "background": `linear-gradient(to top, red 0%, red ${lowCriticalNumber * 100 / highCriticalNumber}%, yellow ${lowCriticalNumber * 100 / highCriticalNumber}%, yellow ${mediumCriticalNumber * 100 / highCriticalNumber}%, #3AD022 ${mediumCriticalNumber * 100 / highCriticalNumber}%, #3AD022 ${criticalNumber * 100 / highCriticalNumber}%, green ${criticalNumber * 100 / highCriticalNumber}%, green 100%)` }} */}
            <div
                className={`bar ${v === 1 && 'fill-blue'}`}
                style={{
                    WebkitTransform: 'rotate(90deg)',
                    MozTransform: 'rotate(90deg)',
                    OTransform: 'rotate(90deg)',
                    msTransform: 'rotate(90deg)',
                    transform: 'rotate(90deg)',
                    background: `linear-gradient(to top, red 0%, red 25%, #FFC312  25%, #FFC312 50%, #3AD022 50%, #3AD022 75%, green 75%, green 100%`,
                    minHeight: '50px',
                    minWidth: '25px',
                    width: '25px',
                    height: '260px'
                }}
            >
                <span
                    className='high-critical'
                    style={{ bottom: `${Math.round((heightOfGraph / 8) * 6)}px`, width: '45px' }}
                >
                    <p
                        style={{
                            WebkitTransform: 'rotate(-90deg)',
                            bottom: '-50px',
                            right: '15px',
                            color: '#006400'
                        }}
                        className={`high-critical-number`}
                    >
                        {highCriticalNumber}
                    </p>
                </span>
                <span
                    className={`critical ${!loaded && `init-0`}`}
                    style={{
                        bottom: `${Math.round((heightOfGraph / 8) * 4)}px`,
                        width: '45px'
                    }}
                >
                    <p
                        style={{
                            WebkitTransform: 'rotate(-90deg)',
                            MozTransform: 'rotate(-90deg)',
                            OTransform: 'rotate(-90deg)',
                            msTransform: 'rotate(-90deg)',
                            transform: 'rotate(-90deg)',
                            bottom: '-50px',
                            right: '15px'
                        }}
                        className={`normal-critical-number `}
                    >
                        {criticalNumber}
                    </p>
                </span>
                <span
                    className={`minimum ${!loaded && `init-0`}`}
                    style={{
                        bottom: `${Math.round(heightOfGraph / 36) - 4}px`,
                        width: '45px'
                    }}
                >
                    <p
                        style={{
                            WebkitTransform: 'rotate(-90deg)',
                            MozTransform: 'rotate(-90deg)',
                            OTransform: 'rotate(-90deg)',
                            msTransform: 'rotate(-90deg)',
                            transform: 'rotate(-90deg)',
                            bottom: '-50px',
                            right: '15px'
                        }}
                        className={`low-critical-number `}
                    >
                        {' '}
                        {lowCriticalNumber}
                    </p>
                </span>
                <span
                    className={`medium ${!loaded && `init-0`}`}
                    style={{
                        bottom: `${Math.round((heightOfGraph / 8) * 2) - 1}px`,
                        width: '45px',
                    }}
                >
                    <p
                        style={{
                            WebkitTransform: 'rotate(-90deg)',
                            MozTransform: 'rotate(-90deg)',
                            OTransform: 'rotate(-90deg)',
                            msTransform: 'rotate(-90deg)',
                            transform: 'rotate(-90deg)',
                            bottom: '-50px',
                            right: '15px',
                            color: '#FFC312'
                        }}
                        className={`medium-critical-number`}
                    >
                        {' '}
                        {mediumCriticalNumber}
                    </p>
                </span>
                <span
                    className={`current ${!loaded && `init-0`}`}
                    style={{
                        bottom: `${Math.round(calculateCurrentScore())}px`
                    }}
                >
                    <p
                        style={{ WebkitTransform: 'rotate(-90deg)', right: '-15px', bottom: '-50px' }}
                        className={`current-score2 `}
                    >
                        {currentScore}
                    </p>
                </span>
                {/*rock && <span className={`rock-section`} style={{
                    bottom: `0px`
                }}>
                    <p className={`rock-section-item`}><img src="/images/scaling.png" className="" width={23}></img> Rock</p>
                </span>*/}
                {/*<span className={`progress ${!loaded && `init-0`}`} style={{
                    bottom: `${currentScore < highCriticalNumber ? Math.round((currentScore * heightOfGraph) / highCriticalNumber) - 1 : Math.round((criticalNumber * heightOfGraph) / criticalNumber) - 1}px`
                }}>
                    <p className={`current-score `}>{currentScore} hi</p>
                </span>*/}
                <div
                    className={`stage ${!loaded && 'init-0'} ${v === 2 && 'stage-4'}`}
                    style={{
                        height: `${((criticalNumber - data.middleTopNumber) / criticalNumber) * 100}%`
                    }}
                ></div>
                <div
                    className={`stage ${!loaded && 'init-0'} ${v === 2 && 'stage-3'}`}
                    style={{
                        height: `${((data.middleTopNumber - data.middleBottomNumber) / criticalNumber) * 100}%`
                    }}
                ></div>
                <div
                    className={`stage ${!loaded && 'init-0'} ${v === 2 && 'stage-2'}`}
                    style={{
                        height: `${((data.middleBottomNumber - lowCriticalNumber) / criticalNumber) * 100}%`
                    }}
                ></div>
                <div
                    className={`stage ${!loaded && 'init-0'} ${v === 2 && 'stage-1'}`}
                    style={{
                        height: `${(lowCriticalNumber / criticalNumber) * 100}%`
                    }}
                ></div>
            </div>

            <div
                className='info-right container column'
                style={{
                    justifyContent: `${justifyRightInfo}`
                }}
            >
                {canEdit && (
                    <div className='modify-score container column'>
                        <small>Modify score</small>
                        <button className='btn' onClick={() => setCurrentScore(currentScore + 1)}>
                            <PlusIcon height={40} width={40} />
                        </button>
                        <button
                            className='btn'
                            onClick={() => setCurrentScore(currentScore > 0 ? currentScore - 1 : 0)}
                        >
                            <MinusIcon height={40} width={40} color='blue' />
                        </button>
                    </div>
                )}

                {v === 2 && (
                    <div className='metrics container'>
                        <div>
                            <div className='metric metric-4'>{criticalNumber}</div>
                            <div className='metric metric-3'>{data.middleTopNumber}</div>
                            <div className='metric metric-2'>{data.middleBottomNumber}</div>
                            <div className='metric metric-1'>{lowCriticalNumber}</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ProgressBar
