import { useState, useEffect } from 'react'
import { MinusCircleIcon, PlusIcon } from '../../public/icons'
import './inverseGraph.css'
import React from 'react';


const InverseGraph = ({
    v = 1,
    data,
    unity,
    highCriticalNumber,
    criticalNumber,
    mediumCriticalNumber,
    lowCriticalNumber,
    currentScore: originalScore = 0,
    canEdit = false
}) => {
    const justifyRightInfo = /* canEdit &&  */ 'space-between'
    const heightOfGraph = 260 //350;
    const fourColors = ['#F28393', '#FFD17B', '#6AD485', '#41A69A']
    const [currentScore, setCurrentScore] = useState(originalScore)
    const [loaded, setLoaded] = useState(false)

    // Start animation
    useEffect(() => {
        setLoaded(true)
    }, [])

    useEffect(() => {
        !canEdit && setCurrentScore(originalScore)
    })

    const calculateCurrentScore = () => {
        if (highCriticalNumber > mediumCriticalNumber) {
            if (currentScore < lowCriticalNumber) {
                return heightOfGraph
            }

            if (currentScore === lowCriticalNumber) {
                return (heightOfGraph/4)*3
            }

            if (currentScore <= mediumCriticalNumber) {
                const med = Math.abs((currentScore - mediumCriticalNumber) / (mediumCriticalNumber - lowCriticalNumber)) * 65
                return (heightOfGraph /2) + med
            }

            if (currentScore <= criticalNumber && currentScore < highCriticalNumber) {
                const med = Math.abs((currentScore - criticalNumber) / (mediumCriticalNumber - criticalNumber)) * 65
                return (heightOfGraph /4) + med
            }

            if (currentScore <= highCriticalNumber) {
                const med = Math.abs((currentScore - highCriticalNumber) / (highCriticalNumber - criticalNumber)) 
                return (heightOfGraph/4) * med
            }

            if (currentScore === highCriticalNumber) {
                return 0
            }

        } else if (highCriticalNumber < mediumCriticalNumber) {
            if (currentScore === lowCriticalNumber) {
                return (heightOfGraph / 4) * 3
            }

            if (currentScore > lowCriticalNumber) {
                return heightOfGraph
            }

            if (currentScore >= mediumCriticalNumber) {
                const med = Math.abs((currentScore - mediumCriticalNumber) / (mediumCriticalNumber - lowCriticalNumber))
                return heightOfGraph / 2 + ((heightOfGraph / 4) * med)
            }

            if (currentScore >= criticalNumber) {
                const med = Math.abs((currentScore - criticalNumber) / (criticalNumber - mediumCriticalNumber))
                return heightOfGraph / 4 * med + heightOfGraph/4
            }

            if (currentScore > highCriticalNumber) {
                const med = Math.abs((currentScore - highCriticalNumber) / (highCriticalNumber - criticalNumber))
                return heightOfGraph / 4 * med
            }

            if (currentScore <= highCriticalNumber){
                return 0
            }
        }
    }

    return (
        <div className='progress-bar container'>
            {/*style={{ "background": `linear-gradient(to top, red 0%, red ${lowCriticalNumber * 100 / highCriticalNumber}%, yellow ${lowCriticalNumber * 100 / highCriticalNumber}%, yellow ${mediumCriticalNumber * 100 / highCriticalNumber}%, #3AD022 ${mediumCriticalNumber * 100 / highCriticalNumber}%, #3AD022 ${criticalNumber * 100 / highCriticalNumber}%, green ${criticalNumber * 100 / highCriticalNumber}%, green 100%)` }} */}
            <div
                className={`bar ${v === 1 && 'fill-blue'}`}
                style={{
                    WebkitTransform: 'rotate(90deg)',
                    MozTransform: 'rotate(90deg)',
                    OTransform: 'rotate(90deg)',
                    msTransform: 'rotate(90deg)',
                    transform: 'rotate(90deg)',
                    background: `linear-gradient(to bottom, red 0%, red 25%, #FFC312 25%, #FFC312 50%, #3AD022 50%, #3AD022 75%, green 75%, green 100%`,
                    minWidth: '25px',
                    width: '25px',
                    height: '260px'
                }}
            >
                <span
                    className={`bottom-high ${!loaded && `init-0`}`}
                    style={{
                        bottom: `${1}px`,
                        width: '40px'
                    }}
                >
                    <p
                        style={{ WebkitTransform: 'rotate(-90deg)', bottom: '-50px', right: '15px' }}
                        className={`bottom-high-number `}
                    >
                        {' '}
                        {highCriticalNumber}
                    </p>
                </span>
                <span
                    className={`critical ${!loaded && `init-0`}`}
                    style={{
                        bottom: `${heightOfGraph - Math.round((heightOfGraph / 4) * 3)}px`,
                        width: '45px'
                    }}
                >
                    <p
                        style={{ WebkitTransform: 'rotate(-90deg)', bottom: '-50px', right: '15px' }}
                        className={`normal-critical-number `}
                    >
                        {criticalNumber}
                    </p>
                </span>
                <span
                    className={`minimum ${!loaded && `init-0`}`}
                    style={{
                        bottom: `${heightOfGraph - Math.round(heightOfGraph / 4) + 1}px`,
                        width: '45px'
                    }}
                >
                    <p
                        style={{ WebkitTransform: 'rotate(-90deg)', bottom: '-50px', right: '15px' }}
                        className={`low-critical-number `}
                    >
                        {' '}
                        {lowCriticalNumber}
                    </p>
                </span>
                <span
                    className={`medium ${!loaded && `init-0`}`}
                    style={{
                        bottom: `${heightOfGraph - Math.round((heightOfGraph / 4) * 2)}px`,
                        width: '45px'
                    }}
                >
                    <p
                        style={{
                            WebkitTransform: 'rotate(-90deg)',
                            bottom: '-50px',
                            right: '15px',
                            color: '#FFC312'
                        }}
                        className={`medium-critical-number `}
                    >
                        {' '}
                        {mediumCriticalNumber}
                    </p>
                </span>
                <span
                    className={`current ${!loaded && `init-0`}`}
                    style={{
                        bottom: `${Math.round(calculateCurrentScore())}px`
                    }}
                >
                    <p
                        style={{ WebkitTransform: 'rotate(-90deg)', right: '-15px', bottom: '-10px' }}
                        className={`current-score2 `}
                    >
                        {' '}
                        {currentScore}
                    </p>
                </span>
                <div
                    className={`stage ${!loaded && 'init-0'} ${v === 2 && 'stage-4'}`}
                    style={{
                        height: `${((criticalNumber - data.middleTopNumber) / criticalNumber) * 100}%`
                    }}
                ></div>
                <div
                    className={`stage ${!loaded && 'init-0'} ${v === 2 && 'stage-3'}`}
                    style={{
                        height: `${((data.middleTopNumber - data.middleBottomNumber) / criticalNumber) * 100}%`
                    }}
                ></div>
                <div
                    className={`stage ${!loaded && 'init-0'} ${v === 2 && 'stage-2'}`}
                    style={{
                        height: `${((data.middleBottomNumber - lowCriticalNumber) / criticalNumber) * 100}%`
                    }}
                ></div>
                <div
                    className={`stage ${!loaded && 'init-0'} ${v === 2 && 'stage-1'}`}
                    style={{
                        height: `${(lowCriticalNumber / criticalNumber) * 100}%`
                    }}
                ></div>
            </div>
        </div>
    )
}

export default InverseGraph
