export const quarter1Data = [
    {
        title: 'Sales',
        data: [
            'Assign more sales opportunities to management',
            'Generate more leads through a cold caller',
            'Optimizing the Upwork process',
            'Blueprint Strategy',
            'Analysis of a strategy to start sales trips'
        ]
    },
    {
        title: 'Marketing',
        data: [
            'Organic appearance on Google and YouTube',
            'Card players',
            'Face-to-face networking analysis',
            'Blueprint Strategy',
            'Inbound analysis',
            'Ebook production',
            'Videos Tech',
            'Tech Cheat Sheets'
        ]
    },
    {
        title: 'Human Resources',
        data: [
            'Budgets',
            'IRS',
            'Definition of payroll increase plan',
            'Hybrid Model Implementation',
            'ClickCare Unification',
            'Computer equipment policy',
            'Implementation of the new birthday plan',
            'Client tiers',
            'Ebook de reclutamiento',
            'Newsletter improvements'
        ]
    },
    {
        title: 'Organizational development',
        data: [
            'International Clicker Day',
            'Tik-Tok videos related to culture and our values',
            'Disseminate that we are preparing for the great place to work',
            'Best Place to Code Page - Follow Up with'
        ]
    },
    {
        title: 'Engineering',
        data: [
            'Documentation of the most critical processes within the business value chain',
            'Increase profits',
            'Cash conversion cycle of the most critical processes',
            'Documentation of 1:1s processes',
            'Improve the Customer Satisfaction culture',
            'Continue Engineering Career Plans',
            'Reformulation of LevelUp',
            'ClickIT Academy Content',
            'Recruitment Videos'
        ]
    },
    {
        title: 'Recruitment',
        data: [
            'Digital Strategy with release of content and blogs on LinkedIn and social networks and Tik Tok Strategy',
            'Linkage plan with new universities and university conferences',
            'Prospecting process, it starts this week',
            'E/book promotion',
            'New platform to evaluate candidates',
            'Engagement with candidates, gifts'
        ]
    },
    {
        title: 'CI',
        data: [
            'Financial plan 2024',
            'Documentation of core activities of the departments',
            'Documentation of 45-day meetings',
            'CI Area Description',
            'Alignment between departments',
            'Rockefeller Habits Measurement',
        ]
    }
]

export const quarter2Data = [
    // {
    //     title: 'Sales',
    //     data: [
    //         'New sales',
    //         'Sales Manual',
    //         '1 new lead of outbound sales per month',
    //         'linkedin lead generation transition'
    //     ]
    // },
    // {
    //     title: 'Marketing',
    //     data: [
    //         'Lead Generation',
    //         'Talent Strategy - Microsite',
    //         'Recruitment Q2',
    //         'AWS Credits',
    //         'Sales'
    //     ]
    // },
    // {
    //     title: 'Human Resources',
    //     data: [
    //         'Improve Benefits',
    //         'Mental health',
    //         'Adapt offices',
    //         'Diversifcation of gender',
    //         'Hybrid model'
    //     ]
    // },
    // {
    //     title: 'Culture',
    //     data: [
    //         'Client gifts Q2',
    //         'Culture podcast',
    //         'Culture handbook'
    //     ]
    // },
    // {
    //     title: 'Engineering',
    //     data: [
    //         'Define 2 solution architects', 'Javascript bootcamp Q2', 'Implement talent development strategy', 'Engineering events', 'SaaS Training', 'Service delivery - Luis Lugo', 'Career plan for PMs (Héctor) Q2'
    //     ]
    // },
    // {
    //     title: 'Recruitment',
    //     data: [
    //         'Finish talent development microsite -ana - Q2', 'Branding strategy for recruitment', 'Improve strategy of engagement for candidates in pool',  'Improve experience during recruitment process', 'Technical exams / Interview filters for candidates - Q2', 'Standaridize recruitment interviews'
    //     ]
    // },
    // {
    //     title: 'Scaling-up',
    //     data: ['Scaling up trainee (2nd quarter) - onboarding', 'Rockefeller habit - Reports to be defined', 'Follow up on monthly meetings', 'Scaling up onboarding video']
    // }
]

export const quarter3Data = [
    {
        title: 'Sales',
        data: [
            'Static lists.',
            'Dynamic lists.',
            'Personalized campaign for Startups and Healthcare.',
            'Benchmarking strategy.',
            'Referral strategy.',
            'Number of leads assigned to PMs.'
        ]
    },
    {
        title: 'Marketing',
        data: [
            'Healthcare Strategy.',
            'Referral program.',
            'ABM: First campaign.',
            'Spanish website.',
            'ClickIT mascot.',
            'ClickIT Values Campaign.',
            'Glassdoor Strategy.'
        ]
    },
    {
        title: 'Human Resources',
        data: [
            'Annual bonus.',
            'Interns model.',
            'Presentation of salary schemes.',
            'ClickIT Payroll.',
            'Expense format.',
            'International Clickers Day.',
            'CV process.',
            'Analysis of the recruitment process.'
        ]
    },
    {
        title: 'Engineering',
        data: [
            'Sales training plan for PMs.',
            'Ebook Full Product Development for sales.',
            'Optimize the Customer Satisfaction guide.',
            'Document PM compliance processes.',
            'Bootcamp End.'
        ]
    },
    {
        title: 'CI',
        data: [
            'CI operations framework.',
            'Initiatives program.',
            'Rockefeller habits.',
            'CI analysts.',
            'Scaling Up App V.2',
            'Documentation of the CI department.'
        ]
    }
]

export const quarter4Data = [
    // {
    //     title: 'Sales',
    //     data: [
    //         'Professional Sales video',
    //         'New Sales Choreograhy',
    //         'Short Sales videos',
    //         'Sequences Reengineering (Email/Linkedin)',
    //         'ABM Strategy',
    //         'Flash banner DevOps'
    //     ]
    // },
    // {
    //     title: 'Marketing',
    //     data: [
    //         'Leads generation',
    //         'Funnels',
    //         'Career plans for marketing',
    //         ' Marketing yearly budget',
    //         'Multimedia strategy',
    //         'Events',
    //         'Christmas efforts'
    //     ]
    // },
    // {
    //     title: 'Human Resources',
    //     data: [
    //         "Company's Onboarding/Offboarding",
    //         'Personnel equipment and accessories inventory',
    //         ' Hybrid model',
    //         'Change of offices project',
    //         'Career plans for HR',
    //         'Budgets and expense control'
    //     ]
    // },
    // {
    //     title: 'Culture',
    //     data: [
    //         "Strategy to Evangelize the company's core values",
    //         'Rewards and recognition plan',
    //         'Digital content',
    //         'Preparation for Certification GPTW',
    //         'Standardize LATAM Processes',
    //         'ClickIT Army'
    //     ]
    // },
    // {
    //     title: 'Recruitment',
    //     data: [
    //         'Technical exams / Interview filters for candidates',
    //         'Improve referral process',
    //         'Prospecting of Universities for Recruitment',
    //         'Separate by administrative contracts'
    //     ]
    // },
    // {
    //     title: 'Engineering',
    //     data: [
    //         'Recruitment for DevOps Bootcamp',
    //         'Wrap-up for Javascript Bootcamp',
    //         'SaaS guide',
    //         'Career Plans for engineering',
    //         'Closing for LevelUp 2022',
    //         'ClickCare policies',
    //         'Time Estimation Guide',
    //         'Create the Growth Plan for Engineering'
    //     ]
    // },
    // {
    //     title: 'CI',
    //     data: [
    //         'Define strategy for Rockefeller Habbits',
    //         'CI onboarding video',
    //         '2023 Strategy meeting',
    //         'Yearly forecast for 2023 and next 3-5 yrs',
    //         'App refactoring'
    //     ]
    // }
]
