import { fetchRequest } from '.';

export const getScoreboards = (data,department) => {
    return fetchRequest(`/department/${department}/scoreboards?` + new URLSearchParams(data), {
        method: 'GET',
    });   
};

export const register = (scoreboard) => {
    return fetchRequest(`/scoreboard/register`, {
        method: 'POST',
        body: JSON.stringify(scoreboard),
    });
};

export const getMyScoreboards = (data) => {
    return fetchRequest(`/scoreboard/assigned?` + new URLSearchParams(data), {
        method: 'GET',
    });   
}


export const updateScoreboard = (scoreboardSlug, data) => {
    return fetchRequest(`/scoreboard/${scoreboardSlug}/update`, {
        method: "PUT",
        body: JSON.stringify(data),
    });
}

export const hideScoreboard = (scoreboardSlug, data) => {
    return fetchRequest(`/scoreboard/${scoreboardSlug}/hide`,{
        method:'PATCH',
        body: JSON.stringify(data)
    })
}

export const priorityScoreboard = (scoreboardSlug, data) => {
    return fetchRequest(`/scoreboard/${scoreboardSlug}/priority`,{
        method:'PATCH',
        body: JSON.stringify(data)
    })
}
export const deleteScoreboard = (scoreboardSlug) => {
    return fetchRequest(`/scoreboard/${scoreboardSlug}/delete`,{
        method:'DELETE'
    })
}

export const getScoreboardNotes =  (scoreboardSlug) => {
    return fetchRequest(`/scoreboard/${scoreboardSlug}/notes`,{
        method:"GET"
    })
}

export const getScoreboardMetrics =  () => {
    return fetchRequest(`/scoreboard/getmetrics`,{
        method:"GET"
    })
}

export const updateScoreboardsCounter = (scoreboards) => {
    let scores = {
        lowCriticalNumber: 0,
        mediumCriticalNumber: 0,
        criticalNumber: 0,
        highCriticalNumber: 0,
    };
    
    scoreboards.flatMap((scoreboard) => {
        if (!scoreboard.inverse) {
            if (scoreboard.currentScore < scoreboard.lowCriticalNumber) {
                return (scores.lowCriticalNumber += 1);
            }
            if (
                scoreboard.currentScore >= scoreboard.lowCriticalNumber &&
            scoreboard.currentScore < scoreboard.mediumCriticalNumber
            ) {
                return (scores.lowCriticalNumber += 1);
            }
            if (
                scoreboard.currentScore >= scoreboard.mediumCriticalNumber &&
            scoreboard.currentScore < scoreboard.criticalNumber
            ) {
                return (scores.mediumCriticalNumber += 1);
            }
            if (
                scoreboard.currentScore >= scoreboard.criticalNumber &&
            scoreboard.currentScore < scoreboard.highCriticalNumber
            ) {
                return (scores.criticalNumber += 1);
            }
            if (scoreboard.currentScore >= scoreboard.highCriticalNumber) {
                return (scores.highCriticalNumber += 1);
            }
        }
        if (scoreboard.currentScore < scoreboard.highCriticalNumber) {
            return (scores.highCriticalNumber += 1);
        }
        if (
            scoreboard.currentScore >= scoreboard.highCriticalNumber &&
            scoreboard.currentScore < scoreboard.criticalNumber
        ) {
            return (scores.highCriticalNumber += 1);
        }
        if (
            scoreboard.currentScore >= scoreboard.criticalNumber &&
            scoreboard.currentScore < scoreboard.mediumCriticalNumber
        ) {
            return (scores.criticalNumber += 1);
        }
        if (
            scoreboard.currentScore >= scoreboard.mediumCriticalNumber &&
            scoreboard.currentScore < scoreboard.lowCriticalNumber
        ) {
            return (scores.mediumCriticalNumber += 1);
        }
        if (scoreboard.currentScore >= scoreboard.lowCriticalNumber) {
            return (scores.lowCriticalNumber += 1);
        }
    });

    return scores;
}

export const updateSelectedScoreboards = scoreboards => {

    let scores = [[],[],[],[]]

    scoreboards.flatMap((scoreboard) => {
        if (!scoreboard.inverse) {
            if (scoreboard.currentScore < scoreboard.lowCriticalNumber) {
                return scores[0].push(scoreboard);
            }
            if (
                scoreboard.currentScore >= scoreboard.lowCriticalNumber &&
            scoreboard.currentScore < scoreboard.mediumCriticalNumber
            ) {
                return scores[0].push(scoreboard);
            }
            if (
                scoreboard.currentScore >= scoreboard.mediumCriticalNumber &&
            scoreboard.currentScore < scoreboard.criticalNumber
            ) {
                return scores[1].push(scoreboard);
            }
            if (
                scoreboard.currentScore >= scoreboard.criticalNumber &&
            scoreboard.currentScore < scoreboard.highCriticalNumber
            ) {
                return scores[2].push(scoreboard);
            }
            if (scoreboard.currentScore >= scoreboard.highCriticalNumber) {
                return scores[3].push(scoreboard);
            }
        }
        if (scoreboard.currentScore < scoreboard.highCriticalNumber) {
            return scores[3].push(scoreboard);
        }
        if (
            scoreboard.currentScore >= scoreboard.highCriticalNumber &&
            scoreboard.currentScore < scoreboard.criticalNumber
        ) {
            return scores[3].push(scoreboard);
        }
        if (
            scoreboard.currentScore >= scoreboard.criticalNumber &&
            scoreboard.currentScore < scoreboard.mediumCriticalNumber
        ) {
            return scores[2].push(scoreboard);
        }
        if (
            scoreboard.currentScore >= scoreboard.mediumCriticalNumber &&
            scoreboard.currentScore < scoreboard.lowCriticalNumber
        ) {
            return scores[1].push(scoreboard);
        }
        if (scoreboard.currentScore >= scoreboard.lowCriticalNumber) {
            return scores[0].push(scoreboard);
        }
    });

    
    return scores;
}