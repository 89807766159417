import React, { useEffect, useState } from "react"

const ProgressBar = (props) => {
    /*const [superGreen, setSuperGreen] = useState(0)
	const [green, setGreen] = useState(0)
	const [yellow, setYellow] = useState(0)
	const [red, setRed] = useState(0)

	useEffect(()=>{
		sco
	}, [])*/
    const { bgcolor, completed, total, showed, selected } = props;

    const containerStyles = {
        height: 20,
        width: '100%',
        backgroundColor: '#e0e0de',
        borderRadius: 50,
    //borderBottom: `3px solid ${bgcolor}`
    }

    const fillerStyles = {
        height: '100%',
        width: `${completed < 20 ? 20 : completed}%`,
        backgroundColor: bgcolor,
        borderRadius: 'inherit',
        textAlign: 'right',
    //boxShadow: `0 3px 15px ${bgcolor}`,
    }

    const labelStyles = {
        padding: 5,
        color: 'white',
        fontWeight: 'bold'
    }

    const mainContainer = selected ? {
        borderBottom: `3px solid ${bgcolor}`,
        paddingBottom: "2px",
        width: "100%",
        cursor:"pointer",
    } : {width: "100%",cursor:"pointer"}

    return (
        <div className="" style={mainContainer}>
            <div style={containerStyles} className="">
                {/*aqui estas arriba de cada indicador */}
                <div style={fillerStyles}>
                    {/*esto es lo que va dentro de los indicadores de progreso*/}
                    <span style={labelStyles}>{`${showed === 0 ? showed : showed ? showed : completed}/ ${total}`}</span>
                </div>
            </div>
        </div>
    );
}
export default ProgressBar