import { ROLES } from '../const';
import AuthGlobal from '../context/store/global';
import { useContext } from 'react';
import { TIMEZONE } from '../const';
import moment from 'moment-timezone';

export const isAdmin = () => {
    const { globalState: { user }} = useContext(AuthGlobal);
    return user.role === ROLES.ADMINISTRATOR ? true : false;
}

export const isEditor = () => {
    const { globalState: { user }} = useContext(AuthGlobal);
    return user.role === ROLES.EDITOR ? true : false;
}

export const isLeader = () => {
    const { globalState: { user, current_department}} = useContext(AuthGlobal);
    return user.role === ROLES.LEADER && user.department.some( ({ slug }) => slug === current_department)
}

export const isUser = () => {
    const { globalState: { user }} = useContext(AuthGlobal);
    return user.role === ROLES.USER ? true : false;
}

export const ownsKpi = (assignedTo) => {
    const { globalState: { user }} = useContext(AuthGlobal);
    return user._id === assignedTo;
}

export const canEditScoreboard = (deadlineDate) => {
    const today = moment.tz(TIMEZONE).endOf('day');

    return today.tz(TIMEZONE).isSameOrBefore(deadlineDate);
}