import React, { useState, useEffect, useContext, Fragment } from 'react'
import Router from 'next/router'
import { getDepartments } from '../../utils/departments-helper'
import { isRegistrationAllowed } from '../../utils/quarter-helper'
import AuthGlobal from '../../context/store/global'
import { currentDepartment } from '../../context/actions'
import './sidebar.css'
import { render } from 'nprogress'
import { isAdmin, isEditor } from '../../utils/permissions'

const SideBar = () => {
    const {globalState: { isAuthenticated, current_department }} = useContext(AuthGlobal)
    const [departments, setDepartments] = useState([])
    const [error, setError] = useState('')
    const [showSubs, setShowSubs] = useState({})

    useEffect(() => {
        async function getAllDepartments() {
            const { success, response, error } = await getDepartments()
            if (success && response) {
                setDepartments(response)
            }
        }
        getAllDepartments()
    }, [current_department])

    useEffect(() => {
        const script = document.createElement('script')

        script.src = '/js/scripts.js'
        script.async = true
        script.id = 'handleSidebar'

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    const viewDepartment = department => {

        return window.location.href = `/department/${department.slug}`
    }

    const handleShowSubs = departmentSlug => {
        if (showSubs[departmentSlug]) {
            var updatedShowSubs = {}
            Object.assign(updatedShowSubs, showSubs)
            updatedShowSubs[departmentSlug] = false
            setShowSubs(updatedShowSubs)
            return
        }
        var updatedShowSubs = {}
        Object.assign(updatedShowSubs, showSubs)
        updatedShowSubs[departmentSlug] = true
        setShowSubs(updatedShowSubs)
    }

    return (
        <div className='main-sidebar'>
            <aside id='sidebar-wrapper'>
                <div className='sidebar-brand'>
                    <a href='/'>
                        <img src='../images/newLogo.png' alt='logo' width='100%' />
                    </a>
                </div>
                <div className='sidebar-brand sidebar-brand-sm'>
                    <a href='/'>SU</a>
                </div>
                <ul className='sidebar-menu mt-3' id='mainSidebar'>
                    <li className='menu-header'>DEPARTMENTS</li>
                    {/** sorts alphabetically the department names and shows them */}
                    {departments
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((department, index) => {
                            if (!department.superior && !department.subDepartments.length) {
                                return (
                                    <li key={index}>
                                        <a className='nav-link' onClick={() => viewDepartment(department)}>
                                            <i className='fas fa-laptop fa-lg'></i>
                                            {/**control the departments that dont have sub departments
                                             * (in this case they are: marketing, scaling up and sales)
                                             */}
                                            <span>{department.name}</span>
                                        </a>
                                    </li>
                                )
                            }
                            if (!department.superior) {
                                return (
                                    <Fragment key={index}>
                                        <li className=''>
                                            <a
                                                className={`nav-link`}
                                                onClick={() => handleShowSubs(department.slug)}
                                                style={showSubs[department.slug] ? { backgroundColor: '#f4f6f9' } : {}}
                                            >
                                                <i className='fas fa-laptop fa-lg'></i>
                                                {/**control the departments that have sub departments
                                                 * in this case they are engineering and HR
                                                 */}
                                                <span>{department.name}</span>
                                                <i
                                                    className={`fas fa-caret-${
                                                        showSubs[department.slug] ? 'up' : 'down'
                                                    }`}
                                                ></i>
                                            </a>
                                        </li>
                                        <li
                                            className={showSubs[department.slug] ? 'd-block' : 'd-none'}
                                            key={index}
                                            style={{ backgroundColor: '#f4f6f9' }}
                                        >
                                            <a className='nav-link ml-3' onClick={() => viewDepartment(department)}>
                                                <i className='fas fa-laptop fa-lg'></i>
                                                <span>{department.name}</span>
                                            </a>
                                        </li>
                                        {/** displays and sort alphabetically the subdepartments whenever there are any */}
                                        {department.subDepartments
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((subDepartment, index) => {
                                                return (
                                                    <li
                                                        className={showSubs[department.slug] ? 'd-block' : 'd-none'}
                                                        key={index}
                                                        style={{ backgroundColor: '#f4f6f9' }}
                                                    >
                                                        <a
                                                            className='nav-link ml-3 '
                                                            onClick={() => viewDepartment(subDepartment)}
                                                        >
                                                            <i className='fas fa-server fa-lg'></i>
                                                            <span>{subDepartment.name}</span>
                                                        </a>
                                                    </li>
                                                )
                                            })}
                                    </Fragment>
                                )
                            }
                        })}
                    {isAdmin() && (
                        <>
                            <li className='menu-header'>ADMINISTRATION</li>
                            <li>
                                <a className='nav-link' href='/users'>
                                    <i className='fas fa-users fa-lg'></i> <span> Users</span>
                                </a>
                            </li>
                        </>
                    )}
                    {isAdmin() && (
                        <li>
                            <a className='nav-link' href='/departments'>
                                <i className='fas fa-building fa-lg'></i>
                                <span> Departments</span>
                            </a>
                        </li>
                    )}
                    {isAuthenticated && isAdmin() ? (
                        <li>
                            <a className='nav-link' href='/history/general'>
                                <i className='fas fa-history fa-lg'></i>
                                <span> Metrics</span>
                            </a>
                        </li>
                    ) : null}
                    {isAuthenticated && <li className='menu-header'>SCOREBOARDS</li>}
                    {isRegistrationAllowed() &&
                    isAuthenticated ||
                    isAdmin() ? (
                            <li id='registerGoalTag'>
                                <a className='nav-link' href='/scoreboards/registerOwnScoreboard'>
                                    <i className='fas fa-plus-circle fa-lg'></i>
                                    <span> Register Goal</span>
                                </a>
                            </li>
                        ) : null}
                    {(isAuthenticated && (isAdmin() || isEditor())) ? (
                        <li>
                            <a className='nav-link' href='/scoreboards/register'>
                                <i className='fas fa-plus-circle fa-lg'></i>
                                <span> New Scoreboard</span>
                            </a>
                        </li>
                    ) : null}
                    {isAuthenticated ? (
                        <>
                            <li>
                                <a className='nav-link' href='/scoreboards/own'>
                                    <i className='fas fa-check-circle fa-lg'></i> <span> My Scoreboards</span>
                                </a>
                            </li>
                        </>
                    ) : null}
                    {isAuthenticated && isAdmin() ? (
                        <li>
                            <a className='nav-link' href='/history'>
                                <i className='fas fa-history fa-lg'></i>
                                <span> Scoreboards History</span>
                            </a>
                        </li>
                    ) : null}
                </ul>
            </aside>
        </div>
    )
}

export default SideBar
