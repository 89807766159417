import React from 'react'

const Accordion = ({ keyId, title, parentId, data, open }) => {
    return (
        <div className='card'>
            <div className='card-header' id={`heading${keyId}-${parentId}`}>
                <h5 className='mb-0 w-100'>
                    <a
                        className='d-flex '
                        style={{ width: '100%' }}
                        data-toggle='collapse'
                        data-target={`#collapse${keyId}-${parentId}`}
                        aria-expanded={open ? 'open' : 'false'}
                        aria-controls={`collapse${keyId}-${parentId}`}
                    >
                        <i className='fas fa-star pr-3 pt-1' style={{ color: '#F5E925', verticalAlign: 'middle' }}></i>
                        {title}
                        <i className={`fas fa-caret-down ml-auto`}></i>
                    </a>
                </h5>
            </div>
            <div
                id={`collapse${keyId}-${parentId}`}
                className='collapse'
                aria-labelledby={`heading${keyId}-${parentId}`}
                data-parent={`#${parentId}`}
            >
                <div className='card-body'>
                    {data.map((paraph, index) => (
                        <p key={index}>
                            <i className='fas fa-check pr-1' style={{ color: '#F5E925' }}></i>
                            {paraph}
                        </p>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Accordion
