import React from 'react'
import { Chart } from 'chart.js'
import { Bar, Pie } from 'react-chartjs-2'

const DonutChart = (props, ref) => {
    const { superGreen = 0, green = 0, yellow = 0, red = 0, style, id } = props

    const data = () => ({
        labels: ['Red', 'Yellow', 'Green', 'Super Green'],
        datasets: [
            {
                label: 'Metrics',
                data: [red, yellow, green, superGreen],
                /*data: [
                    Math.floor(Math.random() * 50) + 1,
                    Math.floor(Math.random() * 50) + 1,
                    Math.floor(Math.random() * 50) + 1,
                    Math.floor(Math.random() * 50) + 1
                ],*/
                backgroundColor: ['#FF0000', '#FFC312', 'rgb(58, 208, 34)', 'green'],
                borderWidth: 1
            }
        ]
    })

    const options = () => ({
        scales: {
            y: {
                beginAtZero: true
            }
        }
    })

    const createChart = React.useCallback(function () {
    /*console.log('creating')
        const canvas = canvasRef.current

        if (myChart) {
            const context = canvas.getContext('2d')
            context.clearRect(0, 0, canvas.width, canvas.height)
            myChart.destroy()
            myChart = null
        }
        myChart = new Chart(canvas, {
            type: 'pie',
            data: {
                labels: ['Red', 'Yellow', 'Green', 'Super Green'],
                datasets: [
                    {
                        label: 'Metrics',
                        //data: [red, yellow, green, superGreen],
                        data: [
                            Math.floor(Math.random() * 50) + 1,
                            Math.floor(Math.random() * 50) + 1,
                            Math.floor(Math.random() * 50) + 1,
                            Math.floor(Math.random() * 50) + 1
                        ],
                        backgroundColor: ['#FF0000', '#FFFF00', 'rgb(58, 208, 34)', 'green'],
                        borderWidth: 1
                    }
                ]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        })*/
    }, [])

    return (
        <div style={style} id={`graph-container-${id}`}>
            <Pie data={data()} options={options()} redraw={true}></Pie>
            <Bar
                data={data()}
                options={{
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true
                                }
                            }
                        ]
                    }
                }}
                redraw={true}
            ></Bar>
        </div>
    )
}

export default DonutChart
