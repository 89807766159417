import React, { useState, useContext } from 'react'
import Scoreboard from '../Scoreboard'
import moment from 'moment'
import _ from 'lodash'
import { priorityScoreboard } from '../../utils/scoreboards-helper'
import scoresUpdate from './scoresUpdate.css'
import AuthGlobal from '../../context/store/global'
import Router from 'next/router'

const GenericScore = ({
    scoreboard
}) => {
    let _month = new Date().getMonth();
    let _quarter = Math.floor((_month + 3) / 3);

    // get both dates with .getTime() in miliseconds, then apply a conversion to get hours
    //Subtraction of the real-time date, minus scoreboard.updated date
    var msDiff = new Date().getTime() - new Date(scoreboard.updated).getTime()
    var daysTillneeded = Math.floor(msDiff / (1000 * 60 * 60 * 24)) // days
    //default date of creation
    var createdHours = new Date().getTime() - new Date(scoreboard.created).getTime()
    var daysSinceCreation = Math.floor(createdHours / (1000 * 60 * 60 * 24)) //days

    //message on the pop-up, all wrapped
    var quarter = _.get(scoreboard, 'quarter', moment(scoreboard.deadlineDates).subtract(10, 'days').calendar())
    var created = moment.utc(scoreboard.created).format('LL')
    var qc = 'Quarter: ' + quarter + ', Created at: ' + created
    var lastUpdate = scoreboard.updated //if updated property its undefined, then use creation date
    if (lastUpdate === undefined) {
        lastUpdate = moment(scoreboard.created).fromNow()
    } else lastUpdate = moment(scoreboard.updated).fromNow()
    var lastModif = 'Last Modification: ' + lastUpdate
    var completedOn = 'Completed on: ' + moment.utc(scoreboard.updated).format('LL')

    if (_quarter !== quarter) {
        var humanDeadlineDate = moment(scoreboard.deadlineDates)
        var updatedDate = moment(scoreboard.updated)
        if (updatedDate === undefined) {
            updatedDate = moment(scoreboard.created)
        } else if (scoreboard.currentScore !== 0) {
            updatedDate = moment(scoreboard.updated)
        } else {
            updatedDate = moment(scoreboard.updated)
        }
        var timeLeft = humanDeadlineDate.diff(updatedDate)
        var daysTillneeded = Math.abs(Math.floor(timeLeft / (1000 * 60 * 60 * 24)))
        if (daysTillneeded >= 1 && daysTillneeded < 2) {
            var lastModif = 'Last Modification: ' + daysTillneeded + ' day ago before deadline date'
        } else {
            lastModif = 'Last Modification: ' + daysTillneeded + ' days ago before deadline date'
        }
        if (daysTillneeded >= 30 && daysTillneeded <= 62) {
            lastModif = 'Last Modification: A month ago before deadline date'
        }
        if (daysTillneeded > 62) {
            var numberOfMonths = Math.floor(daysTillneeded / 30)
            lastModif = `Last Modification: ${numberOfMonths} months ago before deadline date`
        }
    }

    const oneWeek = () => {
        // Binomial functions that dictate the colors, according with the last modification date
        if (
            (daysTillneeded <= 7 && scoreboard.currentScore < scoreboard.highCriticalNumber) ||
            (daysTillneeded <= 7 &&
                scoreboard.currentScore >= scoreboard.highCriticalNumber &&
                scoreboard.inverse !== false) ||
            (scoreboard.updated === undefined &&
                daysSinceCreation <= 7 &&
                scoreboard.currentScore < scoreboard.highCriticalNumber &&
                scoreboard.inverse === false) ||
            (scoreboard.updated === undefined && daysSinceCreation <= 7 && scoreboard.inverse === true)
        ) {
            return true
        }
        return false
    }

    const twoWeeks = () => {
        if (
            (daysTillneeded > 7 &&
                daysTillneeded < 15 &&
                scoreboard.currentScore > scoreboard.highCriticalNumber &&
                scoreboard.inverse !== false) ||
            (daysTillneeded > 7 &&
                daysTillneeded < 15 &&
                scoreboard.currentScore < scoreboard.highCriticalNumber &&
                scoreboard.inverse === false) ||
            (scoreboard.updated === undefined &&
                daysSinceCreation > 7 &&
                daysSinceCreation < 15 &&
                scoreboard.currentScore === 0 &&
                scoreboard.currentScore < scoreboard.highCriticalNumber) ||
            (daysSinceCreation > 7 &&
                daysSinceCreation < 15 &&
                scoreboard.updated === undefined &&
                scoreboard.currentScore <= scoreboard.highCriticalNumber)
        ) {
            return true
        }
        return false
    }

    const threeWeeks = () => {
        if (
            (daysTillneeded >= 15 && daysTillneeded < 22 && scoreboard.currentScore < scoreboard.highCriticalNumber) ||
            (scoreboard.updated === undefined &&
                daysSinceCreation >= 15 &&
                daysSinceCreation < 22 &&
                scoreboard.currentScore === 0 &&
                scoreboard.currentScore < scoreboard.highCriticalNumber) ||
            (scoreboard.currentScore === 0 &&
                daysSinceCreation >= 15 &&
                daysSinceCreation < 22 &&
                scoreboard.updated === undefined &&
                scoreboard.currentScore < scoreboard.highCriticalNumber)
        ) {
            return true
        }
        return false
    }

    const fourWeeks = () => {
        if (
            (daysTillneeded >= 22 && scoreboard.currentScore < scoreboard.highCriticalNumber) ||
            (scoreboard.updated === undefined &&
                daysSinceCreation >= 22 &&
                scoreboard.currentScore === 0 &&
                scoreboard.currentScore < scoreboard.highCriticalNumber) ||
            (scoreboard.currentScore === 0 &&
                daysSinceCreation >= 22 &&
                scoreboard.updated === undefined &&
                scoreboard.currentScore < scoreboard.highCriticalNumber)
        ) {
            return true
        }
        return false
    }

    const scoreboardsCompleted = () => {
        if (scoreboard.currentScore >= scoreboard.highCriticalNumber && scoreboard.inverse === false) {
            return true
        }
        return false
    }

    return (
        <div className='card' style={{backgroundColor: "#ffffff" }}>
            <div
                className='card-header'
                style={{
                    background: scoreboard.priority ? '#FA9800' : '#054aaf',
                }}
            >
                <h6 className='text-white'>
                    {scoreboard.name}
                </h6>
            </div>
            <div className='card-body'>
                <ul className='list-unstyled list-unstyled-border'>
                    <li className='media'>
                        <div className='media-body'>
                            {/*<div className="float-right text-primary">{moment(scoreboard.deadlineDates).subtract(10, 'days').calendar()}</div>*/}
                            <div style={{ overflowWrap: 'normal', fontSize: '18px' }}>{scoreboard.description}</div>
                        </div>
                    </li>
                </ul>
                <Scoreboard data={scoreboard} />
                <div className=''>
                    <div className='float-left stringStyle'>
                        {' '}
                        Deadline :{' '}
                        <span className='text-primary'>{moment.utc(scoreboard.deadlineDates).format('LL')}</span>
                        <button className='card__info graphValue' data-msg={scoreboard.unity}>
                            {' '}
                            <i className='fa-solid fa-badge-percent value'></i>
                        </button>
                    </div>
                </div>
                <div className='float-right' style={{ paddingTop: '8em' }}>
                    {oneWeek() && (
                        <button className='card__info1 flagIcon' data-msg={lastModif}>
                            <i style={{ color: '#006400' }} className='fa-solid fa-flag'></i>
                        </button>
                    )}
                    {twoWeeks() && (
                        <button className='card__info2 flagIcon' data-msg={lastModif}>
                            {' '}
                            <i style={{ color: '#3AD022' }} className='fa-solid fa-flag'></i>
                        </button>
                    )}
                    {threeWeeks() && (
                        <button className='card__info3 flagIcon' data-msg={lastModif}>
                            {' '}
                            <i style={{ color: '#FFC312' }} className='fa-solid fa-flag'></i>
                        </button>
                    )}
                    {fourWeeks() && (
                        <button className='card__info4 flagIcon' data-msg={lastModif}>
                            {' '}
                            <i style={{ color: 'red' }} className='fa-solid fa-flag'></i>
                        </button>
                    )}
                    {scoreboardsCompleted() && (
                        <button className='card__info6 flagIcon' data-msg={completedOn}>
                            {' '}
                            <i style={{ color: 'blue' }} className='fa-solid fa-flag'></i>
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default GenericScore
