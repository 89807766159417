import Dashboard from '../Dashboard'
import React from 'react';

const MainContent = ({ children }) => {
    
    return(
        <div id="app">
            {
                children ? children : <Dashboard/>
            }
        </div>
    )
}

export default MainContent;