/*
Month      getMonth()  quarter
---------  ----------  -------
January         0         1
February        1         1
March           2         1
April           3         2
May             4         2
June            5         2
July            6         3
August          7         3
September       8         3
October         9         4
November       10         4
December       11         4
*/

import { QUARTER_END_DATES, TIMEZONE } from "../const"
import moment from "moment-timezone";

export function getQuarter() {
    const month = new Date().getMonth();
    return Math.floor(month / 3) + 1;
}

export function getQuarterToString(quarter) {
    return `Quarter ${quarter}`;
}

export function getDeadline(quarter) {
    return `${QUARTER_END_DATES[quarter - 1]}`;
}

export function getRegistrationDeadline() {
    const currentQuarter = getQuarter();
    const deadline = moment.tz(QUARTER_END_DATES[(currentQuarter + 2) % 4], TIMEZONE)
        .add(15, 'days')
        .toDate();
    return deadline;
}

export function isRegistrationAllowed() {
    const now = moment.tz(TIMEZONE).toDate();
    return now <= getRegistrationDeadline();
}